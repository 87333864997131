import React from 'react'
import { Container, Link, Typography } from '@mui/material'
import Layout from '../components/layout'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const AboutPage = () => {
    const {
        site: {
            siteMetadata: { siteUrl, siteTitle },
        },
    } = useSiteMetadata()

    const title = `このサイトについて | ${siteTitle}`
    const description = `このサイトについて`
    return (
        <Layout title={title} description={description}>
            <Container maxWidth="md" sx={{ pt: { xs: 3, sm: 6 } }}>
                <Typography gutterBottom variant="h1" sx={{ mb: 4 }}>
                    技術ブログまとめましたとは
                </Typography>
                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >
                    このサイトは技術ブログをまとめたサイトです。
                    <br />
                    各技術ブログのRSSを平日9時に自動収集しています。
                    <br />
                    <br />
                    技術ブログの追加申請はこちらから
                    <br />
                    <Link href="https://forms.gle/1dHB5SECd1Xz5Wg39">
                        https://forms.gle/1dHB5SECd1Xz5Wg39
                    </Link>
                </Typography>
            </Container>
        </Layout>
    )
}

export default AboutPage
